import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cart from '../../cart/Cart';
import AddressModal from '../../modal/AddressModal';
import FilterModal from '../../modal/FilterModal';
import AlertBox from './AlertBox';

function Footer() {
  return (
    <>
      <div>
        <section className='footer-section bg-section-2 section-padding'>
          <div className='container'>
            <div className='row row-cols-2 row-cols-lg-4 g-4'>
              <div className='col'>
                <div className='footer-widget-6'>
                  <Link className='navbar-brand d-none d-xl-inline' to='/'>
                    <img
                      src='/assets/images/logo.png'
                      className='logo-img'
                      alt
                    />
                  </Link>

                  <h5 className='mb-3 fw-bold'>About Us</h5>
                  <p className='mb-2'>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable.
                  </p>
                  {/* <a className="link-dark" href="javascript:;">
                    Read More
                  </a> */}
                </div>
              </div>

              <div className='col'>
                <div className='footer-widget-8'>
                  <h5 className='mb-3 fw-bold'>Company</h5>
                  <ul className='widget-link list-unstyled'>
                    <li>
                      <Link to='/about-us'>About Us</Link>
                    </li>
                    <li>
                      <Link to='/contact-us'>Contact Us</Link>
                    </li>
                    <li>
                      <Link to='/disclaimer'>Disclaimer</Link>
                    </li>
                    <li>
                      <Link to='/refund-policy'>Refund Policy</Link>
                    </li>
                    <li>
                      <Link to='/privacy-policy'>Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to='/terms-and-conditions'>Terms & Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col'>
                <div className='footer-widget-9'>
                  <h5 className='mb-3 fw-bold'>Follow Us</h5>
                  <div className='social-link d-flex align-items-center gap-2'>
                    <a href='javascript:;'>
                      <i className='bi bi-facebook' />
                    </a>
                    <a href='javascript:;'>
                      <i className='bi bi-twitter' />
                    </a>
                    <a href='javascript:;'>
                      <i className='bi bi-linkedin' />
                    </a>
                    <a href='javascript:;'>
                      <i className='bi bi-youtube' />
                    </a>
                    <a href='javascript:;'>
                      <i className='bi bi-instagram' />
                    </a>
                  </div>
                  <div className='mb-3 mt-3'>
                    <h5 className='mb-0 fw-bold'>Support</h5>
                    <p className='mb-0 text-muted'>info@brownlaces.com</p>
                  </div>
                  <div className>
                    <h5 className='mb-0 fw-bold'>Toll Free</h5>
                    <p className='mb-0 text-muted'>1234567890</p>
                  </div>
                </div>
              </div>
            </div>
            {/*end row*/}
            <div className='my-5' />
            {/* <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <h5 className="fw-bold mb-3">Download Mobile App</h5>
                </div>
                <div className="app-icon d-flex flex-column flex-sm-row align-items-center justify-content-center gap-2">
                  <div>
                    <a href="javascript:;">
                      <img
                        src="assets/images/play-store.webp"
                        width={160}
                        alt
                      />
                    </a>
                  </div>
                  <div>
                    <a href="javascript:;">
                      <img
                        src="assets/images/apple-store.webp"
                        width={160}
                        alt
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            {/*end row*/}
          </div>
        </section>
        {/*end footer*/}
        <footer className='footer-strip text-center py-3 bg-section-2 border-top positon-absolute bottom-0'>
          <p className='mb-0 text-muted'>
            © 2023. Dazorra | All rights reserved.
          </p>
        </footer>

        {/*end quick view*/}
        {/*Start Back To Top Button*/}
        <a href='javaScript:;' className='back-to-top'>
          <i className='bi bi-arrow-up' />
        </a>
      </div>
      <AlertBox />
    </>
  );
}

export default Footer;
